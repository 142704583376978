.Container {
}

.Container h1,
.Container h2,
.Container h3,
.Container h4,
.Container h5,
.Container h6,
.Container ul,
.Container ol,
.Container p {
    margin-bottom: 1rem;
}
.Container p,
.Container ul,
.Container li {
    font-size: 1rem;
}
.Container a {
    color: theme(colors.holon-blue-500);
    border-bottom: 2px solid theme(colors.holon-blue-500);
    text-decoration: none;
}
.Container ul,
.Container ol {
    padding-left: 2rem;
}
.Container ul {
    list-style-type: disc;
}
.Container ol {
    list-style-type: decimal;
}
.Container ol ol {
    list-style-type: lower-alpha;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.Container ul ul {
    list-style-type: circle;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.Container a[data-introduction-text] {
    cursor:
        url("/imgs/wiki-cursor-pointer.svg") 15 34,
        help;
    color: green;
    border-bottom: 2px dashed rgb(0, 170, 0);
}

.Container > *:last-child {
    margin-bottom: 0;
}
