.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #ffffff;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    position: relative;
    @apply bg-holon-blue-900;
}
.slider::after {
    left: 100%;
}
.slider::before {
    right: 100%;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    width: 20px;
    height: 20px;
    @apply bg-holon-blue-500;
    @apply border-holon-blue-900;
    @apply rounded-full;
    @apply appearance-none;
    --tw-shadow: 2.5px 2.5px 0 0;
    --tw-shadow-colored: 2.5px 2.5px 0 0 var(--tw-shadow-color);
    box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    cursor: pointer;
}
.slider::-moz-range-track,
.slider::-webkit-slider-runnable-track {
    z-index: 1;
}

.slider:disabled::-webkit-slider-thumb {
    background-color: grey;
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    @apply bg-holon-blue-500;
    @apply border-holon-blue-900;
    @apply rounded-full;
    @apply appearance-none;
    --tw-shadow: 2.5px 2.5px 0 0;
    --tw-shadow-colored: 2.5px 2.5px 0 0 var(--tw-shadow-color);
    box-shadow:
        var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    cursor: pointer;
}

.slider:disabled::-moz-range-thumb {
    background-color: grey;
}

.slidervalue {
    position: absolute;
    width: calc(100% - 20px);
    margin-left: 10px;
    bottom: 100%;
    margin-bottom: 20px;
}

.slider + .slidervalue output {
    position: absolute;

    width: 40px;
    margin-bottom: 12px;
    height: 20px;
    transform: translateX(-50%);
    text-align: center;
    @apply bg-holon-blue-500;
}
.slider + .slidervalue output:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    @apply bg-holon-blue-500;
    top: 100%;
    margin-top: 8px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
}
.slider:focus + output {
    @apply bg-holon-blue-900;
}
